import * as _stimulus2 from "@hotwired/stimulus";
var _stimulus = "default" in _stimulus2 ? _stimulus2.default : _stimulus2;
var exports = {};
(function (t, e) {
  "object" < "u" ? exports = e(_stimulus) : (t = typeof globalThis < "u" ? globalThis : t || self, t.StimulusRailsNestedForm = e(t.Stimulus));
})(exports, function (t) {
  "use strict";

  class e extends t.Controller {
    add(n) {
      n.preventDefault();
      const r = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime().toString());
      this.targetTarget.insertAdjacentHTML("beforebegin", r);
    }
    remove(n) {
      n.preventDefault();
      const r = n.target.closest(this.wrapperSelectorValue);
      if (r.dataset.newRecord === "true") r.remove();else {
        r.style.display = "none";
        const s = r.querySelector("input[name*='_destroy']");
        s.value = "1";
      }
    }
  }
  return e.targets = ["target", "template"], e.values = {
    wrapperSelector: {
      type: String,
      default: ".nested-form-wrapper"
    }
  }, e;
});
export default exports;